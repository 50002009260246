import React, {
  useState,
  KeyboardEvent,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Send, Camera, Video, MessageCirclePlus, Mic } from "lucide-react";
import { Conversation } from "./types";
import { FaSpinner } from "react-icons/fa";
import { isMobile } from "react-device-detect"; // Add this import

interface InputAreaProps {
  onSendMessage: (message: string, conversationId: string) => Promise<void>;
  onStartNewConversation: () => Promise<Conversation | null>;
  isLoading: boolean;
  currentConversationId: string | null;
  onSendAudio: (
    audioBlob: Blob,
    conversationId: string
  ) => Promise<{
    response: string;
    transcription: string;
    audio_response?: string;
  }>;
  isInputDisabled: boolean;
}

const InputArea: React.FC<InputAreaProps> = ({
  onSendMessage,
  onStartNewConversation,
  isLoading,
  currentConversationId,
  onSendAudio,
  isInputDisabled,
}) => {
  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [, setAudioChunks] = useState<Blob[]>([]);
  const [audioResponse, setAudioResponse] = useState<string | null>(null);
  const [transcriptionError, setTranscriptionError] = useState<string | null>(
    null
  );
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState<
    boolean | null
  >(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioPlaybackAttempted, setAudioPlaybackAttempted] = useState(false);
  const playButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showMobilePlayButton, setShowMobilePlayButton] = useState(false);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "28px"; // Slightly increased to account for padding
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = scrollHeight > 28 ? `${scrollHeight}px` : "28px";
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const handleSend = useCallback(async () => {
    if (input.trim() && !isLoading && !isSending) {
      setIsSending(true);
      const messageToSend = input.trim();
      setInput("");

      try {
        let conversationId = currentConversationId;
        if (!conversationId) {
          const newConversation = await onStartNewConversation();
          if (newConversation) {
            conversationId = newConversation.id;
          } else {
            throw new Error("Failed to create a new conversation");
          }
        }

        await onSendMessage(messageToSend, conversationId);
      } catch (error) {
        console.error("Error in handleSend:", error);
        setInput(messageToSend);
      } finally {
        setIsSending(false);
      }
    }
  }, [
    input,
    isLoading,
    isSending,
    currentConversationId,
    onStartNewConversation,
    onSendMessage,
  ]);

  const handleStartNewConversation = async () => {
    try {
      setIsSending(true);
      const newConversation = await onStartNewConversation();
      if (newConversation) {
        console.log("New conversation created:", newConversation);
      } else {
        console.error("Failed to create a new conversation");
      }
    } catch (error) {
      console.error("Error creating new conversation:", error);
    } finally {
      setIsSending(false);
    }
  };

  const [hoveringButton, setHoveringButton] = useState<string | null>(null);

  const tooltipStyle =
    "absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-green-50 text-green-800 text-xs py-1 px-3 rounded-full z-10 whitespace-nowrap border border-green-200 shadow-sm";

  const showAdditionalIcons = input.trim() === "";

  const [isRecording, setIsRecording] = useState(false);
  const [permissionError, setPermissionError] = useState<string | null>(null);

  const checkMicrophonePermission = useCallback(async () => {
    if (hasMicrophonePermission !== null) {
      return hasMicrophonePermission;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      setHasMicrophonePermission(true);
      return true;
    } catch (error) {
      console.error("Error checking microphone permission:", error);
      setHasMicrophonePermission(false);
      if (error instanceof DOMException) {
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          setPermissionError(
            "Microphone access was denied. Please allow microphone access in your browser settings and try again."
          );
        } else if (
          error.name === "NotFoundError" ||
          error.name === "DevicesNotFoundError"
        ) {
          setPermissionError(
            "No microphone found. Please ensure your microphone is properly connected and try again."
          );
        } else {
          setPermissionError(
            "An error occurred while trying to access the microphone. Please check your settings and try again."
          );
        }
      } else {
        setPermissionError(
          "An unexpected error occurred. Please try again or check your browser settings."
        );
      }
      return false;
    }
  }, [hasMicrophonePermission]);

  const toggleRecording = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("Toggle recording clicked");

    if (isRecording) {
      console.log("Stopping recording");
      stopRecording();
    } else {
      console.log("Starting recording");
      const hasPermission = await checkMicrophonePermission();
      if (hasPermission) {
        await startRecording();
      } else {
        console.log("No microphone permission");
        // The error message is already set by checkMicrophonePermission
      }
    }
  };

  const startRecording = async () => {
    try {
      setPermissionError(null);
      console.log("Attempting to start recording...");

      // Use different constraints for mobile devices
      const constraints = isMobile
        ? { audio: true, video: false }
        : { audio: true };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log("Got media stream:", stream);

      // For mobile, we'll use a different MIME type
      const mimeType = isMobile ? "audio/mp4" : "audio/webm";
      const mediaRecorder = new MediaRecorder(stream, { mimeType });

      mediaRecorderRef.current = mediaRecorder;

      const chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (event) => {
        console.log("Data available event:", event.data.size);
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        console.log("MediaRecorder stopped");
        console.log("Number of chunks:", chunks.length);
        const audioBlob = new Blob(chunks, { type: mimeType });
        console.log("Audio blob created:", audioBlob.size);
        setAudioChunks(chunks);
        await sendAudioMessage(audioBlob);
      };

      // For mobile, we'll use a fixed time limit for recording
      const MOBILE_RECORD_LIMIT = 120000; // 120 seconds
      mediaRecorder.start();
      console.log("MediaRecorder started");
      setIsRecording(true);

      if (isMobile) {
        setTimeout(() => {
          if (mediaRecorderRef.current?.state === "recording") {
            stopRecording();
          }
        }, MOBILE_RECORD_LIMIT);
      }
    } catch (error: unknown) {
      console.error("Error starting recording:", error);
      setPermissionError(
        `An error occurred while trying to access the microphone: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    }
  };

  const stopRecording = useCallback(() => {
    console.log("Stopping recording...");
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      console.log("MediaRecorder stopped");
      // Clean up the media stream
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => track.stop());
      setIsRecording(false);
    } else {
      console.log("MediaRecorder not in recording state");
    }
  }, []);

  const sendAudioMessage = async (audioBlob: Blob) => {
    try {
      console.log(`Sending audio message. Blob size: ${audioBlob.size} bytes`);
      if (audioBlob.size === 0) {
        throw new Error("Audio blob is empty");
      }
      let conversationId = currentConversationId;
      if (!conversationId) {
        const newConversation = await onStartNewConversation();
        if (newConversation) {
          conversationId = newConversation.id;
        } else {
          throw new Error("Failed to create a new conversation");
        }
      }
      const response = await onSendAudio(audioBlob, conversationId);
      console.log("Full audio response:", response);
      console.log("Audio response type:", typeof response.audio_response);
      console.log(
        "Audio response length:",
        response.audio_response ? response.audio_response.length : "N/A"
      );

      if (response.audio_response) {
        console.log(
          "Audio response received, length:",
          response.audio_response.length
        );
        setAudioResponse(response.audio_response);
      } else {
        console.warn("No audio_response in the response object");
      }
      setTranscriptionError(null); // Clear any previous errors
    } catch (error: unknown) {
      console.error("Error sending audio message:", error);
      if (
        error instanceof Error &&
        error.message.includes("Transcription failed")
      ) {
        setTranscriptionError(error.message);
      } else {
        setPermissionError(
          `Failed to send audio message. Please try again. ${
            error instanceof Error ? error.message : String(error)
          }`
        );
      }
    }
  };

  // Modify the useEffect for audio playback
  useEffect(() => {
    console.log(
      "Audio response state changed:",
      audioResponse ? "Present" : "Not present"
    );
    if (audioResponse) {
      console.log("Attempting to play audio response");
      const audio = new Audio(`data:audio/mp3;base64,${audioResponse}`);
      audioRef.current = audio;

      const playAudio = () => {
        audio
          .play()
          .then(() => {
            console.log("Audio playback started successfully");
            setAudioPlaybackAttempted(true);
            setShowMobilePlayButton(false);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
            if (isMobile) {
              setShowMobilePlayButton(true);
            } else if (!audioPlaybackAttempted) {
              setAudioPlaybackAttempted(true);
              // Create a play button for manual interaction (desktop fallback)
              const playButton = document.createElement("button");
              playButton.textContent = "Play Audio Response";
              playButton.className =
                "bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-2";
              playButton.onclick = () => {
                audio
                  .play()
                  .then(() =>
                    console.log("Audio played after user interaction")
                  )
                  .catch((err) =>
                    console.error(
                      "Error playing audio after user interaction:",
                      err
                    )
                  );
              };
              document.body.appendChild(playButton);
              playButtonRef.current = playButton;
            }
          });
      };

      if (!isMobile) {
        playAudio();
      } else {
        setShowMobilePlayButton(true);
      }

      // Cleanup function
      return () => {
        if (audioRef.current) {
          console.log("Cleaning up audio playback");
          audioRef.current.pause();
          audioRef.current = null;
        }
        // Remove the play button if it exists
        if (playButtonRef.current) {
          playButtonRef.current.remove();
          playButtonRef.current = null;
        }
        setShowMobilePlayButton(false);
      };
    }
  }, [audioResponse, audioPlaybackAttempted]);

  const handleMobilePlayClick = () => {
    if (audioRef.current) {
      audioRef.current
        .play()
        .then(() => {
          console.log("Audio played after user interaction on mobile");
          setShowMobilePlayButton(false);
        })
        .catch((err) => console.error("Error playing audio on mobile:", err));
    }
  };

  return (
    <div className="bg-gradient-to-b from-green-50 to-green-100 p-4 shadow-lg">
      <div className="max-w-2xl mx-auto">
        {permissionError && (
          <div className="text-red-600 bg-red-100 p-2 rounded mb-4 text-sm">
            <p>{permissionError}</p>
            <p className="mt-2">To grant microphone access in Arc:</p>
            <ol className="list-decimal list-inside mt-1">
              <li>Click the lock icon in the address bar</li>
              <li>Find "Microphone" in the list</li>
              <li>Change the setting to "Allow"</li>
              <li>Refresh the page and try again</li>
            </ol>
          </div>
        )}
        {transcriptionError && (
          <div className="text-red-600 bg-red-100 p-2 rounded mb-4 text-sm">
            <p>{transcriptionError}</p>
            <p className="mt-2">To resolve this issue:</p>
            <ol className="list-decimal list-inside mt-1">
              <li>Check your language settings in your profile</li>
              <li>
                Ensure you're speaking in the language set in your profile
              </li>
              <li>Try speaking more clearly and slowly</li>
              <li>If the issue persists, try typing your message instead</li>
            </ol>
          </div>
        )}

        {showMobilePlayButton && (
          <button
            onClick={handleMobilePlayClick}
            className="inline-flex items-center justify-center px-3 py-2 bg-green-500 hover:bg-green-600 text-white text-sm font-medium rounded-full shadow-sm transition-colors duration-200 ease-in-out mb-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
            Play
          </button>
        )}

        <div className="relative flex items-center bg-white rounded-2xl border border-green-200 hover:border-green-300 transition-all duration-300 shadow-sm py-1">
          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              adjustTextareaHeight();
            }}
            onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            disabled={isLoading || isInputDisabled}
            className="flex-grow px-3 text-gray-800 placeholder-gray-500 
              bg-transparent
              focus:outline-none focus:ring-0
              text-sm leading-6
              placeholder:italic placeholder:font-light
              resize-none overflow-hidden"
            style={{ height: "28px", maxHeight: "150px" }}
          />
          <div className="flex items-center space-x-1 pr-2">
            <button
              onClick={handleSend}
              disabled={isLoading || isSending}
              className="rounded-full p-0.5 bg-green-500 hover:bg-green-600 
              transition-all duration-300 text-white h-7 w-7 flex items-center justify-center"
            >
              {isLoading || isSending ? (
                <FaSpinner className="animate-spin" size={16} />
              ) : (
                <Send size={16} />
              )}
            </button>
            {showAdditionalIcons && (
              <>
                <div
                  className="relative group"
                  onMouseEnter={() => setHoveringButton("camera")}
                  onMouseLeave={() => setHoveringButton(null)}
                >
                  <div className="rounded-full p-0.5 bg-gray-100 cursor-not-allowed h-7 w-7 flex items-center justify-center">
                    <Camera size={16} color="#4A5568" />
                  </div>
                  {hoveringButton === "camera" && (
                    <span className={tooltipStyle}>Coming Soon</span>
                  )}
                </div>
                <div
                  className="relative group"
                  onMouseEnter={() => setHoveringButton("video")}
                  onMouseLeave={() => setHoveringButton(null)}
                >
                  <div className="rounded-full p-0.5 bg-gray-100 cursor-not-allowed h-7 w-7 flex items-center justify-center">
                    <Video size={16} color="#4A5568" />
                  </div>
                  {hoveringButton === "video" && (
                    <span className={tooltipStyle}>Coming Soon</span>
                  )}
                </div>
                <button
                  onClick={toggleRecording}
                  disabled={isLoading}
                  className={`rounded-full p-0.5 transition-all duration-300 h-7 w-7 flex items-center justify-center ${
                    isRecording
                      ? "bg-red-500 text-white"
                      : "bg-green-100 hover:bg-green-200 text-green-800"
                  }`}
                >
                  <Mic size={16} />
                  {isMobile && isRecording && (
                    <span className="ml-1 text-xs">60s</span>
                  )}
                </button>
                <button
                  onClick={handleStartNewConversation}
                  disabled={isLoading}
                  className="rounded-full p-0.5 bg-green-100 hover:bg-green-200 transition-all duration-300 h-7 w-7 flex items-center justify-center"
                >
                  <MessageCirclePlus size={16} color="#1A237E" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputArea;
