import React, { useEffect, useRef, useState, useCallback } from "react";
import InputArea from "./InputArea";
import { Conversation, Message } from "./types";
import { useApi } from "./apiUtils";
import backgroundImage from "../assets/chat_background.jpg";
import ChatMessages from "./ChatMessages";
import { FaSpinner } from "react-icons/fa";

interface AudioResult {
  response: string;
  transcription: string;
  audio_response?: string; // Changed from string | null to string | undefined
}
interface ChatContainerProps {
  onNewConversation: () => Promise<Conversation | null>;
  onUpdateConversation: (updatedConversation: Conversation) => Promise<void>;
  onSendMessage: (message: string, conversationId: string) => Promise<void>;
  onSendAudio: (
    audioBlob: Blob,
    conversationId: string
  ) => Promise<{
    response: string;
    audio_response?: string;
    transcription: string;
  }>;
  currentConversation: Conversation | null;
  currentConversationId: string | null;
  isLoading: boolean;
  // Add this prop
  setIsLoading: (isLoading: boolean) => void;
  isInitialMessageReceived: boolean;
  setIsInitialMessageReceived: (value: boolean) => void;
}

const ChatContainer: React.FC<ChatContainerProps> = ({
  onNewConversation,
  onSendMessage,
  currentConversation,
  currentConversationId,
  isLoading: isAppLoading,
  onSendAudio,
  setIsLoading,
  isInitialMessageReceived,
  setIsInitialMessageReceived,
}) => {
  const { getConversationMessages } = useApi();
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isWaitingForWelcome, setIsWaitingForWelcome] = useState(false);
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);

  useEffect(() => {
    if (currentConversation && currentConversation.messages) {
      setMessages(currentConversation.messages);
      setIsMessagesLoading(false);
      setIsWaitingForWelcome(false);
      setIsInitialMessageReceived(currentConversation.messages.length > 0);
    } else if (currentConversationId) {
      setIsMessagesLoading(true);
      setIsWaitingForWelcome(true);
      getConversationMessages(currentConversationId)
        .then((fetchedMessages: Message[]) => {
          setMessages(fetchedMessages);
          setIsMessagesLoading(false);
          setIsWaitingForWelcome(fetchedMessages.length === 0);
          setIsLoading(false);
          setIsInitialMessageReceived(fetchedMessages.length > 0);
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
          setIsMessagesLoading(false);
          setIsWaitingForWelcome(false);
          setIsLoading(false);
          setIsInitialMessageReceived(false);
        });
    } else {
      setMessages([]);
      setIsWaitingForWelcome(false);
      setIsLoading(false);
      setIsInitialMessageReceived(false);
    }
  }, [
    currentConversation,
    currentConversationId,
    getConversationMessages,
    setIsLoading,
    setIsInitialMessageReceived,
  ]);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100); // Small delay to ensure content is rendered
    }
  }, []);

  useEffect(() => {
    const container = document.querySelector(".overflow-y-auto");
    if (container) {
      container.scrollTop = 0; // Reset scroll position when messages change
    }
  }, [currentConversationId]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (currentConversationId) {
      setIsMessagesLoading(true);
      getConversationMessages(currentConversationId)
        .then((fetchedMessages: Message[]) => {
          setMessages(fetchedMessages);
          setIsMessagesLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
          setIsMessagesLoading(false);
        });
    } else {
      // Clear messages when there's no current conversation
      setMessages([]);
    }
  }, [currentConversationId, getConversationMessages]);

  const handleSendMessage = async (message: string, conversationId: string) => {
    setIsLocalLoading(true);
    try {
      const userMessage: Message = {
        content: message,
        created_at: new Date().toISOString(),
        is_user: true,
        conversation_id: conversationId,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      await onSendMessage(message, conversationId);

      const updatedMessages = await getConversationMessages(conversationId);
      setMessages(updatedMessages);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLocalLoading(false);
    }
  };

  const handleSendAudio = useCallback(
    async (audioBlob: Blob, conversationId: string): Promise<AudioResult> => {
      setIsAudioLoading(true);
      try {
        console.log("Audio blob size:", audioBlob.size);
        console.log("Audio blob type:", audioBlob.type);

        const response = await onSendAudio(audioBlob, conversationId);

        // Fetch the updated conversation messages
        const updatedMessages = await getConversationMessages(conversationId);
        setMessages(updatedMessages);

        setIsAudioLoading(false);
        return response;
      } catch (error) {
        setIsAudioLoading(false);
        console.error("Error sending audio:", error);
        // ... existing error handling ...
        throw error;
      }
    },
    [onSendAudio, getConversationMessages]
  );

  useEffect(() => {
    async function checkMicrophoneAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        console.log("Microphone access granted", stream);
        const track = stream.getAudioTracks()[0];
        console.log("Audio track settings:", track.getSettings());
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    }

    checkMicrophoneAccess();
  }, []);

  return (
    <div
      className="flex flex-col h-full relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col h-full z-10">
        <div className="flex-1 overflow-y-auto overscroll-contain pt-6 sm:pt-8">
          <div className="max-w-3xl w-full mx-auto pb-6 sm:pb-8">
            {isAppLoading || isMessagesLoading || isWaitingForWelcome ? (
              <div className="flex items-center justify-center h-64">
                <FaSpinner className="animate-spin text-4xl text-green-500" />
              </div>
            ) : messages.length > 0 ? (
              <div className="w-full">
                <ChatMessages messages={messages} />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-64 text-center p-4">
                <svg
                  className="w-20 h-20 sm:w-28 sm:h-28 text-green-500/80 mb-6"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  />
                </svg>
                <h3 className="text-xl sm:text-2xl font-semibold text-green-600/90 mb-3">
                  Welcome! I am ioda, your AI Wellness Coach
                </h3>
                <p className="text-sm sm:text-base text-gray-600 max-w-md leading-relaxed">
                  I'm here to provide a safe space where you can share your
                  worries, explore your feelings, and find practical ways to
                  manage stress. Let's work together towards your emotional
                  well-being.
                </p>
              </div>
            )}
            <div ref={messagesEndRef} className="h-px" />
          </div>
        </div>
        <InputArea
          onSendMessage={handleSendMessage}
          onStartNewConversation={onNewConversation}
          onSendAudio={handleSendAudio}
          isLoading={
            isAppLoading ||
            isMessagesLoading ||
            isWaitingForWelcome ||
            isLocalLoading ||
            isAudioLoading
          }
          currentConversationId={currentConversation?.id || null}
          isInputDisabled={!isInitialMessageReceived}
        />
      </div>
    </div>
  );
};

export default ChatContainer;
