// index.tsx
import ReactDOM from "react-dom/client";
import App from "./App";
import { FronteggProvider } from "@frontegg/react";
import "./index.css";
import React from "react";

const contextOptions = {
  baseUrl: process.env.REACT_APP_FRONTEGG_DOMAIN as string,
  clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID as string,
  appId: process.env.REACT_APP_FRONTEGG_APP_ID as string,
};

// Optionally, you can add a runtime check to ensure these values are set
if (
  !contextOptions.baseUrl ||
  !contextOptions.clientId ||
  !contextOptions.appId
) {
  throw new Error(
    "Missing required Frontegg configuration. Check your environment variables."
  );
}

const authOptions = {
  keepSessionAlive: true,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox={true}
      authOptions={authOptions}
    >
      <App />
    </FronteggProvider>
  </React.StrictMode>
);
