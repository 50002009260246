// apiUtils.tsx
import axios from "axios";
import { useAuth } from "@frontegg/react";
import { useState, useCallback } from "react";
import { Conversation, Message } from "./types";

const API_URL = process.env.REACT_APP_API_URL;

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

interface Voice {
  id: string;
  name: string;
}

interface FeedbackData {
  messageContent: string;
  conversationId: string;
  messageId: string;
  feedback: string;
}

export const useApi = () => {
  const { user, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin =
    user?.roles?.some(
      (role: { name: string }) => role.name.toLowerCase() === "admin"
    ) || false;

  const authenticatedRequest = useCallback(
    async <T,>(
      method: string,
      url: string,
      data?: any,
      config?: any
    ): Promise<T> => {
      if (!isAuthenticated || !user?.accessToken) {
        console.error("User is not authenticated");
        throw new Error("User is not authenticated");
      }

      console.log("Sending authenticated request:", {
        method,
        url,
        isAuthenticated,
        hasAccessToken: !!user?.accessToken,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          ...(config?.headers || {}),
        },
        data,
      });

      setIsLoading(true);

      try {
        const response = await apiService.request({
          method,
          url,
          data,
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            ...(config?.headers || {}),
          },
          ...config,
        });
        return response.data;
      } catch (error) {
        console.error(`Error in ${method} ${url}:`, error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [isAuthenticated, user]
  );

  const createNewConversation = useCallback(() => {
    return authenticatedRequest<Conversation>("POST", "/conversations").then(
      (response) => {
        console.log("New conversation created:", response);
        return response;
      }
    );
  }, [authenticatedRequest]);

  const sendMessage = useCallback(
    async (message: string, conversationId: string | null) => {
      if (!conversationId) {
        // If there's no conversation, create a new one
        const newConversation = await createNewConversation();
        conversationId = newConversation.id;
      }

      return authenticatedRequest<{ response: string; current_step: string }>(
        "POST",
        "/chat",
        {
          message,
          conversation_id: conversationId,
        }
      );
    },
    [authenticatedRequest, createNewConversation]
  );

  const deleteConversation = useCallback(
    (conversationId: string) => {
      return authenticatedRequest<void>(
        "DELETE",
        `/conversations/${conversationId}`
      );
    },
    [authenticatedRequest]
  );

  const updateConversationTitle = useCallback(
    async (conversationId: string, newTitle: string) => {
      console.log(
        `Updating conversation ${conversationId} with title: ${newTitle}`
      ); // Add this log
      return authenticatedRequest<Conversation>(
        "PATCH",
        `/conversations/${conversationId}`,
        { title: newTitle }
      );
    },
    [authenticatedRequest]
  );

  const getConversations = useCallback(() => {
    return authenticatedRequest<Conversation[]>("GET", "/conversations");
  }, [authenticatedRequest]);

  const getConversationMessages = useCallback(
    (conversationId: string) => {
      return authenticatedRequest<Message[]>(
        "GET",
        `/conversations/${conversationId}/messages`
      );
    },
    [authenticatedRequest]
  );

  const saveConfig = useCallback(
    (
      voiceMode: string,
      voiceName: string,
      language: string,
      gender: string
    ) => {
      return authenticatedRequest<void>("POST", "/config", {
        voice_mode: voiceMode,
        voice_name: voiceName,
        language,
        gender,
      });
    },
    [authenticatedRequest]
  );

  const getConfig = useCallback(() => {
    return authenticatedRequest<{
      voice_mode: string;
      voice_id: string;
      voice_name: string;
      language: string;
      gender: string;
    }>("GET", "/config");
  }, [authenticatedRequest]);

  const getAvailableLanguages = useCallback(() => {
    return authenticatedRequest<{ languages: string[] }>(
      "GET",
      "/available-languages"
    );
  }, [authenticatedRequest]);

  const getAvailableVoices = useCallback(() => {
    return authenticatedRequest<{ voices: Voice[] }>(
      "GET",
      "/available-voices"
    );
  }, [authenticatedRequest]);

  const sendAudioMessage = useCallback(
    async (audioBlob: Blob, conversationId: string | null) => {
      const formData = new FormData();
      formData.append("audio", audioBlob, "audio.wav");
      if (conversationId) {
        formData.append("conversation_id", conversationId);
      }

      console.log("Sending audio message:", {
        conversationId,
        blobSize: audioBlob.size,
        endpoint: "/chat/audio",
      });

      const audioConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      };

      try {
        const response = await authenticatedRequest<{
          response: string;
          audio_response?: string;
          transcription: string;
        }>("POST", "/chat/audio", formData, audioConfig);

        console.log("Audio message response:", response);
        return response;
      } catch (error: unknown) {
        console.error(
          "Error in sendAudioMessage:",
          error instanceof Error ? error.message : String(error)
        );
        throw error;
      }
    },
    [authenticatedRequest, user]
  );

  const deleteAllConversations = useCallback(() => {
    return authenticatedRequest<void>("DELETE", "/conversations");
  }, [authenticatedRequest]);

  const refreshKnowledge = useCallback(() => {
    return authenticatedRequest<{ message: string }>(
      "POST",
      "/refresh-knowledge"
    );
  }, [authenticatedRequest]);

  const submitFeedback = useCallback(
    (feedbackData: FeedbackData) => {
      return authenticatedRequest<void>("POST", "/feedback", feedbackData);
    },
    [authenticatedRequest]
  );

  return {
    isLoading,
    isAdmin,
    sendMessage,
    createNewConversation,
    deleteConversation,
    updateConversationTitle,
    getConversations,
    getConversationMessages,
    saveConfig,
    getConfig,
    getAvailableLanguages,
    getAvailableVoices,
    sendAudioMessage,
    deleteAllConversations,
    refreshKnowledge,
    submitFeedback,
  };
};
