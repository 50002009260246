import React, { useState, useMemo, useRef, useEffect } from "react";
import { Button } from "../@/components/ui/button";
import { Input } from "../@/components/ui/input";
import {
  Trash2,
  Edit2,
  MessageSquare,
  Plus,
  Search,
  X,
  Trash,
} from "lucide-react";
import { Conversation } from "./types";
import { FaSpinner } from "react-icons/fa";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  conversations: Conversation[];
  onSelectConversation: (id: string) => void;
  onDeleteConversation: (id: string) => Promise<void>;
  onUpdateConversationTitle: (id: string, newTitle: string) => Promise<void>;
  onNewConversation: () => Promise<Conversation | null>;
  currentConversationId: string | null;
  onConversationsUpdate: (updatedConversations: Conversation[]) => void;
  onDeleteAllConversations: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
  onRefreshConversations: () => Promise<void>;
}

const Sidebar: React.FC<SidebarProps> = ({
  isOpen,
  onClose,
  conversations,
  onSelectConversation,
  onDeleteConversation,
  onUpdateConversationTitle,
  onNewConversation,
  currentConversationId,
  onDeleteAllConversations,
  setIsLoading,
  onRefreshConversations,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editTitle, setEditTitle] = useState("");
  const [isLoading] = useState(false);

  const filteredConversations = useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase().trim();
    return conversations.filter((conv) => {
      const title = (conv.title || "Untitled Conversation").toLowerCase();
      return title.includes(lowercasedSearchTerm);
    });
  }, [conversations, searchTerm]);

  const handleEditClick = (id: string, title: string) => {
    setEditingId(id);
    setEditTitle(title);
  };

  const handleTitleSubmit = async (id: string) => {
    try {
      console.log(`Sidebar: Saving title for conversation ${id}`); // Add this log
      await onUpdateConversationTitle(id, editTitle);
      setEditingId(null);
    } catch (error) {
      console.error("Error updating conversation title:", error);
    }
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await onDeleteConversation(id);
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const handleNewConversationClick = async () => {
    setIsLoading(true);
    const newConversation = await onNewConversation();
    if (newConversation && newConversation.id) {
      onSelectConversation(newConversation.id);
      onClose();
    }
    setIsLoading(false);
  };

  const renderConversationItem = (conversation: Conversation) => {
    const isEditing = editingId === conversation.id;
    const isSelected = currentConversationId === conversation.id;
    const title = conversation.title || "Untitled Conversation";

    return (
      <div
        key={conversation.id}
        className={`mb-2 rounded-md transition-all duration-200 ${
          isSelected
            ? "bg-green-200/50"
            : "bg-green-50/50 hover:bg-green-100/50"
        }`}
      >
        {isEditing ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleTitleSubmit(conversation.id);
            }}
            className="flex p-0.5"
          >
            <Input
              value={editTitle}
              onChange={(e) => setEditTitle(e.target.value)}
              className="flex-1 mr-0.5 text-sm bg-green-50/50 !border-green-200 hover:!border-green-300 focus:!border-green-300 focus:!ring-2 focus:!ring-green-200 !outline-none rounded-full"
            />
            <Button
              type="submit"
              size="sm"
              className="px-0.5 py-0.5 bg-green-500 text-white hover:bg-green-600 rounded-full"
            >
              Save
            </Button>
          </form>
        ) : (
          <div className="flex items-center py-1.5 px-2">
            <MessageSquare
              size={12}
              className="text-green-400 mr-2 flex-shrink-0"
            />
            <div className="flex-1 min-w-0 relative group">
              <span
                className="block truncate cursor-pointer text-green-800 text-xs"
                onClick={() => onSelectConversation(conversation.id)}
              >
                {title}
              </span>
              <span className="absolute left-0 top-full mt-1 w-full p-2 bg-green-50/90 shadow-lg rounded-md text-sm text-green-900 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10 pointer-events-none">
                {title}
              </span>
            </div>
            <Button
              onClick={() => handleEditClick(conversation.id, title)}
              className="p-1 bg-transparent hover:bg-green-100 rounded-full border-transparent hover:border-green-200 focus:border-green-200 focus:ring-1 focus:ring-green-100 outline-none ml-1"
            >
              <Edit2 size={10} className="text-green-500" />
            </Button>
            <Button
              onClick={() => handleDeleteClick(conversation.id)}
              className="p-1 bg-transparent hover:bg-red-50 rounded-full border-transparent hover:border-red-200 focus:border-red-200 focus:ring-1 focus:ring-red-100 outline-none ml-1"
            >
              <Trash2 size={10} className="text-red-400" />
            </Button>
          </div>
        )}
      </div>
    );
  };

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      onRefreshConversations();
    }
  }, [isOpen, onRefreshConversations]);

  return (
    <div
      ref={sidebarRef}
      className={`fixed inset-y-0 left-0 w-full sm:w-64 bg-green-100/90 border-r border-green-200 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out z-20 shadow-lg flex flex-col`}
    >
      <div className="flex justify-between items-center p-4 border-b border-green-200 bg-green-50/90">
        <h2 className="text-lg font-semibold text-green-800">Conversations</h2>
        <Button
          onClick={onClose}
          variant="ghost"
          size="sm"
          className="rounded-full hover:bg-green-200 hover:bg-opacity-70"
        >
          <X size={20} />
        </Button>
      </div>
      <div className="p-3 space-y-3">
        <div className="relative">
          <Input
            type="text"
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-green-50/80 border-green-200 focus:!border-green-300 focus:!ring-2 focus:!ring-green-300 !outline-none hover:border-green-200 transition-all duration-300 pl-10 rounded-full shadow-md"
          />
          <Search
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-green-500"
            size={16}
          />
        </div>
        <Button
          onClick={handleNewConversationClick}
          className="w-full bg-blue-100 hover:bg-blue-200 text-blue-700 rounded-full shadow-sm transition-colors duration-200 text-xs py-2"
        >
          <Plus size={12} className="mr-1" /> New Conversation
        </Button>
      </div>
      <div className="flex-grow overflow-y-auto px-3">
        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <FaSpinner className="animate-spin text-4xl text-green-500" />
          </div>
        ) : filteredConversations.length > 0 ? (
          filteredConversations.map(renderConversationItem)
        ) : (
          <p className="text-center text-gray-500 mt-4">
            No conversations found
          </p>
        )}
      </div>
      <div className="p-3">
        <Button
          onClick={onDeleteAllConversations}
          className="w-full bg-red-100 hover:bg-red-200 text-red-600 rounded-full shadow-sm transition-colors duration-200 text-xs py-2"
        >
          <Trash size={12} className="mr-1" /> Delete All Conversations
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
