import React from "react";
import Logo from "../assets/logo.svg";
import { Button } from "../@/components/ui/button";
import { Menu, Settings } from "lucide-react";

interface HeaderProps {
  onToggleSidebar: () => void;
  onConfigPanelToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({
  onToggleSidebar,
  onConfigPanelToggle,
}) => {
  return (
    <header className="bg-green-100 p-2 flex items-center justify-between shadow-md z-20">
      <div className="flex items-center">
        <Button
          onClick={onToggleSidebar}
          variant="ghost"
          className="mr-2 hover:bg-green-200 rounded-full transition-all duration-300"
          size="sm"
        >
          <Menu size={20} className="text-green-700" />
        </Button>
        {/* Removed the relative div and the blurred background */}
        <img src={Logo} alt="Ioda Logo" className="h-8 sm:h-10" />
      </div>
      <Button
        onClick={onConfigPanelToggle}
        variant="ghost"
        className="hover:bg-green-200 transition-colors duration-200 rounded-full p-1 z-30"
        size="sm"
      >
        <Settings className="h-5 w-5 text-green-700" />
      </Button>
    </header>
  );
};

export default Header;
