import React, { useState, useEffect, useCallback } from "react";
import { useAuth, useLoginWithRedirect, ContextHolder } from "@frontegg/react";
import Header from "./components/Header";
import ChatContainer from "./components/ChatContainer";
import Sidebar from "./components/SideBar";
import ConfigPanel from "./components/ConfigPanel";
import { useApi } from "./components/apiUtils";
import { Conversation } from "./components/types";
import { FaSpinner } from "react-icons/fa";

const logout = () => {
  const baseUrl = ContextHolder.getContext().baseUrl;
  window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
};

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const loginWithRedirect = useLoginWithRedirect();
  const api = useApi();
  const {
    getConversations,
    createNewConversation,
    getConversationMessages,
    deleteConversation,
    saveConfig,
    getConfig,
    deleteAllConversations,
    sendMessage,
    refreshKnowledge,
  } = api;

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 640);
  const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialMessageReceived, setIsInitialMessageReceived] =
    useState(false);

  const fetchConversations = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedConversations = await getConversations();
      setConversations(fetchedConversations);
      setIsLoading(false);
      return fetchedConversations;
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setIsLoading(false);
      return [];
    }
  }, [getConversations]);

  useEffect(() => {
    if (isAuthenticated) {
      const storedConversationId = localStorage.getItem(
        "currentConversationId"
      );
      fetchConversations().then((fetchedConversations) => {
        if (storedConversationId) {
          const storedConversation = fetchedConversations.find(
            (conv) => conv.id === storedConversationId
          );
          if (storedConversation) {
            setCurrentConversation(storedConversation);
          } else if (fetchedConversations.length > 0) {
            setCurrentConversation(
              fetchedConversations[fetchedConversations.length - 1]
            );
          }
        } else if (fetchedConversations.length > 0) {
          setCurrentConversation(
            fetchedConversations[fetchedConversations.length - 1]
          );
        }
      });
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, fetchConversations, loginWithRedirect]);

  useEffect(() => {
    if (currentConversation) {
      localStorage.setItem("currentConversationId", currentConversation.id);
    }
  }, [currentConversation]);

  useEffect(() => {
    if (conversations.length > 0 && !currentConversation) {
      setCurrentConversation(conversations[conversations.length - 1]);
    }
  }, [conversations, currentConversation]);

  const handleLogout = useCallback(() => {
    logout();
  }, []);

  const handleSendMessage = useCallback(
    async (message: string, conversationId: string) => {
      try {
        console.log(
          "Sending message in App:",
          message,
          "to conversation:",
          conversationId
        );
        const { response, current_step } = await sendMessage(
          message,
          conversationId
        );
        console.log("Message sent successfully:", response);

        // Update the conversations state
        setConversations((prevConversations) =>
          prevConversations.map((conv) =>
            conv.id === conversationId
              ? {
                  ...conv,
                  messages: [
                    ...(conv.messages || []),
                    {
                      content: message,
                      created_at: new Date().toISOString(),
                      is_user: true,
                      conversation_id: conversationId,
                    },
                    {
                      content: response,
                      created_at: new Date().toISOString(),
                      is_user: false,
                      conversation_id: conversationId,
                    },
                  ],
                  currentStep: current_step,
                }
              : conv
          )
        );

        // Update the current conversation if it's the active one
        setCurrentConversation((prev) => {
          if (prev && prev.id === conversationId) {
            return {
              ...prev,
              messages: [
                ...(prev.messages || []),
                {
                  content: message,
                  created_at: new Date().toISOString(),
                  is_user: true,
                  conversation_id: conversationId,
                },
                {
                  content: response,
                  created_at: new Date().toISOString(),
                  is_user: false,
                  conversation_id: conversationId,
                },
              ],
              currentStep: current_step,
            };
          }
          return prev;
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    [sendMessage]
  );

  const handleNewConversation = useCallback(async () => {
    try {
      const newConversation = await createNewConversation();
      const conversationWithMessages = {
        ...newConversation,
        messages: [],
      };
      setConversations((prevConversations) => [
        ...prevConversations,
        conversationWithMessages,
      ]);
      setCurrentConversation(conversationWithMessages);
      localStorage.setItem(
        "currentConversationId",
        conversationWithMessages.id
      );

      setIsInitialMessageReceived(false); // Reset the flag for the new conversation

      // Trigger welcome message and wait for it to complete
      await handleSendMessage("", conversationWithMessages.id);

      // Fetch the updated messages after sending the welcome message
      const updatedMessages = await getConversationMessages(
        conversationWithMessages.id
      );

      // Update the conversation with the fetched messages
      const updatedConversation = {
        ...conversationWithMessages,
        messages: updatedMessages,
      };
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.id === updatedConversation.id ? updatedConversation : conv
        )
      );
      setCurrentConversation(updatedConversation);
      setIsInitialMessageReceived(true); // Set the flag after receiving the initial message

      return updatedConversation;
    } catch (error) {
      console.error("Error creating new conversation:", error);
      return null;
    }
  }, [createNewConversation, handleSendMessage, getConversationMessages]);

  const handleUpdateConversation = async (
    updatedConversation: Conversation
  ) => {
    setConversations(
      conversations.map((conv) =>
        conv.id === updatedConversation.id ? updatedConversation : conv
      )
    );
    setCurrentConversation(updatedConversation);
    localStorage.setItem("currentConversationId", updatedConversation.id);
  };

  const handleSelectConversation = (id: string) => {
    const selected = conversations.find((conv) => conv.id === id);
    if (selected) {
      setCurrentConversation(selected);
      localStorage.setItem("currentConversationId", id);
    } else {
      console.error("Conversation not found:", id);
    }
  };

  const handleDeleteConversation = async (id: string) => {
    try {
      await deleteConversation(id);
      setConversations(conversations.filter((conv) => conv.id !== id));
      if (currentConversation?.id === id) {
        setCurrentConversation(null);
        localStorage.removeItem("currentConversationId");
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  const handleDeleteAllConversations = async () => {
    try {
      await deleteAllConversations();
      setConversations([]);
      setCurrentConversation(null);
      localStorage.removeItem("currentConversationId");
    } catch (error) {
      console.error("Error deleting all conversations:", error);
    }
  };

  const handleUpdateConversationTitle = async (
    id: string,
    newTitle: string
  ) => {
    if (!id) {
      console.error("Conversation ID is undefined");
      return;
    }
    try {
      console.log(
        `Attempting to update conversation ${id} with title: ${newTitle}`
      ); // Add this log
      const updatedConversation = await api.updateConversationTitle(
        id,
        newTitle
      );
      console.log("Updated conversation:", updatedConversation); // Add this log

      // Update the conversations list
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.id === id ? { ...conv, title: newTitle } : conv
        )
      );

      // Update the current conversation if it's the one being edited
      if (currentConversation?.id === id) {
        setCurrentConversation({ ...currentConversation, title: newTitle });
      }
    } catch (error) {
      console.error("Error updating conversation title:", error);
    }
  };

  const handleConversationsUpdate = (updatedConversations: Conversation[]) => {
    setConversations(updatedConversations);
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleConfigPanelToggle = useCallback(() => {
    setIsConfigPanelOpen((prev) => !prev);
  }, []);

  const handleSendAudio = useCallback(
    async (audioBlob: Blob, conversationId: string) => {
      try {
        const response = await api.sendAudioMessage(audioBlob, conversationId);

        // Update conversations state
        setConversations((prevConversations) =>
          prevConversations.map((conv) =>
            conv.id === conversationId
              ? {
                  ...conv,
                  messages: [
                    ...(conv.messages || []),
                    {
                      content: response.transcription,
                      created_at: new Date().toISOString(),
                      is_user: true,
                      conversation_id: conversationId,
                    },
                    {
                      content: response.response,
                      created_at: new Date().toISOString(),
                      is_user: false,
                      conversation_id: conversationId,
                      audio_response: response.audio_response,
                    },
                  ],
                }
              : conv
          )
        );

        // Update current conversation if it's the active one
        setCurrentConversation((prev) => {
          if (prev && prev.id === conversationId) {
            return {
              ...prev,
              messages: [
                ...(prev.messages || []),
                {
                  content: response.transcription,
                  created_at: new Date().toISOString(),
                  is_user: true,
                  conversation_id: conversationId,
                },
                {
                  content: response.response,
                  created_at: new Date().toISOString(),
                  is_user: false,
                  conversation_id: conversationId,
                  audio_response: response.audio_response,
                },
              ],
            };
          }
          return prev;
        });

        return response;
      } catch (error) {
        console.error("Error sending audio:", error);
        throw error;
      }
    },
    [api]
  );

  useEffect(() => {
    const loadConfig = async () => {
      try {
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };
    loadConfig();
  }, [getConfig]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRefreshConversations = useCallback(async () => {
    try {
      const refreshedConversations = await getConversations();
      setConversations(refreshedConversations);
    } catch (error) {
      console.error("Error refreshing conversations:", error);
    }
  }, [getConversations]);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <FaSpinner className="animate-spin text-4xl text-blue-500 mb-4" />
        <h2 className="text-xl font-semibold text-gray-700">
          Authenticating...
        </h2>
        <p className="text-gray-500 mt-2">Please wait while we log you in.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen">
      <Header
        onToggleSidebar={toggleSidebar}
        onConfigPanelToggle={handleConfigPanelToggle}
      />
      <div className="flex flex-1 overflow-hidden relative">
        {isLoading ? (
          <div className="flex items-center justify-center w-full">
            <FaSpinner className="animate-spin text-4xl text-green-500" />
          </div>
        ) : (
          <>
            <Sidebar
              isOpen={isSidebarOpen}
              onClose={() => setIsSidebarOpen(false)}
              conversations={conversations}
              onSelectConversation={handleSelectConversation}
              onDeleteConversation={handleDeleteConversation}
              onUpdateConversationTitle={handleUpdateConversationTitle}
              onNewConversation={handleNewConversation}
              currentConversationId={currentConversation?.id || null}
              onConversationsUpdate={handleConversationsUpdate}
              onDeleteAllConversations={handleDeleteAllConversations}
              setIsLoading={setIsLoading}
              onRefreshConversations={handleRefreshConversations}
            />
            <div className="flex-1 flex flex-col overflow-hidden relative">
              {isConfigPanelOpen && (
                <div className="absolute top-4 right-4 z-50 w-full sm:w-auto">
                  <ConfigPanel
                    isOpen={isConfigPanelOpen}
                    onClose={() => setIsConfigPanelOpen(false)}
                    onLogout={handleLogout}
                    saveConfig={saveConfig}
                    getConfig={getConfig}
                    refreshKnowledge={refreshKnowledge}
                  />
                </div>
              )}
              <ChatContainer
                onNewConversation={handleNewConversation}
                onUpdateConversation={handleUpdateConversation}
                onSendMessage={handleSendMessage}
                onSendAudio={handleSendAudio}
                currentConversation={currentConversation}
                currentConversationId={currentConversation?.id || null}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isInitialMessageReceived={isInitialMessageReceived}
                setIsInitialMessageReceived={setIsInitialMessageReceived}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;
